/* eslint-disable no-unused-vars */
import { Decode } from "@/services";
import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const localStore = localStorage.getItem("iworkUser");
let userData;
if (localStore) {
  userData = JSON.parse(Decode.decode(localStore));
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      name: "warningLetterExportPDF",
      path: "/warningLetterExportPDF/:id",
      component: () => import("@/components/pdf/warningLetterPDF"),
    },
    {
      name: "emailExportPDF",
      path: "/emailExportPDF/:id",
      component: () =>
        import("@/components/pdf/Detail_Services/EmailExportPDF"),
    },
    {
      name: "AppsNewChat",
      path: "/newChat",
      component: () => import("@/views/apps/chat/Index"),
    },
    {
      name: "User",
      path: "/Login",
      component: () => import("@/components/PtnAllService/User/Login"),
    },
    {
      name: "Register",
      path: "/Register",
      component: () => import("@/components/PtnAllService/User/Register"),
    },
    {
      name: "Attendance",
      path: "/attendance",
      component: () => import("@/components/PtnAllService/User/Attendance"),
    },
    {
      name: "AttendanceHorizontal",
      path: "/attendanceHorizontal",
      component: () =>
        import("@/components/PtnAllService/User/AttendanceHorizontal"),
    },
    {
      name: "ScanReceiveItems",
      path: "/scanReceiveItems",
      component: () =>
        import("@/components/PtnAllService/stock/scanReceiveItems"),
    },
    {
      name: "jobStatus",
      path: "/jobStatus",
      component: () =>
        import("@/components/PtnAllService/Contract/details_delivery_public"),
    },
    {
      name: "assetdeviceStatus",
      path: "/assetdeviceStatus",
      component: () =>
        import("@/components/PtnAllService/Contract/details_assetdevice"),
    },
    {
      name: "exportPDFRequestQuotation",
      path: "/exportPDFRequestQuotation/:id",
      component: () =>
        import("@/components/pdf/RequestQuotation/exportPDFRequestQuotation"),
    },
    {
      name: "exportPDFRequestQuotationApp",
      path: "/exportPDFRequestQuotationApp/:id",
      component: () =>
        import("@/components/pdf/index").then((x) => x.PDFQUO || x),
    },
    {
      name: "exportPDFSalesOrder",
      path: "/exportPDFSalesOrder/:id",
      component: () =>
        import("@/components/pdf/index").then((x) => x.PDFSO || x),
    },
    {
      name: "exportPDFTemporaryDeliveryOrder",
      path: "/exportPDFTemporaryDeliveryOrder/:id",
      component: () =>
        import("@/components/pdf/index").then((x) => x.PDFTN || x),
    },
    {
      name: "exportPDFPurchaseOrder",
      path: "/exportPDFPurchaseOrder/:id",
      component: () =>
        import("@/components/pdf/index").then((x) => x.PDFPO || x),
    },
    {
      name: "stickerPDF",
      path: "/pdf/stickerPDF",
      component: () =>
        import("@/components/pdf/index").then((x) => x.stickerPDF || x),
    },
    {
      name: "ExportAttendanceReport",
      path: "/ExportAttendanceReport",
      component: () =>
        import("@/components/PtnAllService/Excel/ExportAttendanceReport"),
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        //PTN route
        {
          name: "DemoTablets",
          path: "/demo-tablets",
          component: () =>
            import("@/components/PtnAllService/demo/tablets/index.vue"),
        },
        {
          name: "DemoAds",
          path: "/demo-ads",
          component: () =>
            import("@/components/PtnAllService/demo/ads/index.vue"),
        },
        {
          name: "UserServices",
          path: "/userServices",
          component: () =>
            import("@/components/PtnAllService/UserServices/index").then(
              (x) => x.Index || x
            ),
        },
        {
          name: "timeSheet",
          path: "/timeSheet",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.timeSheet || x
            ),
        },
        {
          name: "timeSheets",
          path: "/timeSheets",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.timeSheets || x
            ),
        },
        {
          name: "purchaseRequest",
          path: "/purchaseRequest",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.purchaseRequest || x
            ),
          children: [
            {
              name: "purchaseRequestCreatePage",
              path: "create",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.purchaseRequestCreate || x
                ),
            },
            {
              name: "purchaseRequestEditPage",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.purchaseRequestEdit || x
                ),
            },
            {
              name: "purchaseRequestViewPage",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.purchaseRequestView || x
                ),
            },
          ],
        },
        {
          name: "approvePurchaseRequest",
          path: "/approvePurchaseRequest",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.approvePurchaseRequest || x
            ),
          children: [
            {
              name: "approvePurchaseRequestEditPage",
              path: "edit/:status/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.approvePurchaseRequestEdit || x
                ),
            },
            {
              name: "approvePurchaseRequestViewPage",
              path: "view/:status/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.approvePurchaseRequestView || x
                ),
            },
          ],
        },
        {
          name: "accPurchaseRequest",
          path: "/acc-purchase-request",
          component: () =>
            import("@/components/PtnAllService/accounting/index").then(
              (x) => x.accPurchaseRequest || x
            ),
          children: [
            {
              name: "accPurchaseRequestCreatePage",
              path: "create",
              component: () =>
                import("@/components/PtnAllService/accounting/index").then(
                  (x) => x.accPurchaseRequestCreate || x
                ),
            },
            {
              name: "accPurchaseRequestEditPage",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/accounting/index").then(
                  (x) => x.accPurchaseRequestEdit || x
                ),
            },
            {
              name: "accPurchaseRequestViewPage",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/accounting/index").then(
                  (x) => x.accPurchaseRequestView || x
                ),
            },
          ],
        },
        {
          name: "approveAccPurchaseRequest",
          path: "/approve-acc-purchase-request",
          component: () =>
            import("@/components/PtnAllService/accounting/index").then(
              (x) => x.approveAccPurchaseRequest || x
            ),
          children: [
            {
              name: "approveAccPurchaseRequestEditPage",
              path: "edit/:status/:id",
              component: () =>
                import("@/components/PtnAllService/accounting/index").then(
                  (x) => x.approveAccPurchaseRequestEdit || x
                ),
            },
            {
              name: "approveAccPurchaseRequestViewPage",
              path: "view/:status/:id",
              component: () =>
                import("@/components/PtnAllService/accounting/index").then(
                  (x) => x.approveAccPurchaseRequestView || x
                ),
            },
          ],
        },
        {
          name: "requisition",
          path: "/requisition",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.requisition || x
            ),
          children: [
            {
              name: "requisitionCreatePage",
              path: "create",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.requisitionCreate || x
                ),
            },
            {
              name: "requisitionEditPage",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.requisitionEdit || x
                ),
            },
            {
              name: "requisitionEditHRPage",
              path: "editHR/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.requisitionEditHR || x
                ),
            },
            {
              name: "requisitionViewPage",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.requisitionView || x
                ),
            },
            {
              name: "requisitionViewHR",
              path: "viewHR/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.requisitionViewHR || x
                ),
            },
          ],
        },
        {
          name: "approveRequisition",
          path: "/approveRequisition",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.approveRequisition || x
            ),
          children: [
            {
              name: "approveRequisitionEditPage",
              path: "edit/:status/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.approveRequisitionEdit || x
                ),
            },
            {
              name: "approveRequisitionViewPage",
              path: "view/:status/:id",
              component: () =>
                import("@/components/PtnAllService/HR/index").then(
                  (x) => x.approveRequisitionView || x
                ),
            },
          ],
        },
        {
          name: "approveGiftVoucher",
          path: "/approveGiftVoucher",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.approveGiftVoucher || x
            ),
        },
        {
          name: "giftVoucher",
          path: "/giftVoucher",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.giftVoucher || x
            ),
        },
        {
          name: "officeEquipment",
          path: "/officeEquipments",
          component: () =>
            import("@/components/PtnAllService/masterData/index").then(
              (x) => x.officeEquipment || x
            ),
        },
        {
          name: "refurbished",
          path: "/refurbished",
          component: () =>
            import(
              "@/components/PtnAllService/Contract/refurbished/refurbishedPage"
            ),
        },
        {
          name: "refurbishedParts",
          path: "/refurbishedParts",
          component: () =>
            import(
              "@/components/PtnAllService/stock/RefurbishedPart/RefurbishedPartIndex"
            ),
        },
        {
          name: "reports",
          path: "/reports",
          component: () =>
            import("@/components/PtnAllService/Reports/index").then(
              (x) => x.Index || x
            ),
          children: [
            {
              name: "HRIndex",
              path: "hr",
              component: () =>
                import("@/components/PtnAllService/Reports/index").then(
                  (x) => x.HRIndex || x
                ),
            },
            {
              name: "SOIndex",
              path: "salesOrder",
              component: () =>
                import("@/components/PtnAllService/Reports/index").then(
                  (x) => x.SOIndex || x
                ),
            },
            {
              name: "StockIndex",
              path: "stock",
              component: () =>
                import("@/views/apps/stock-report/StockReport").then(
                  (x) => x.StockIndex || x
                ),
            },
            {
              name: "CarReportIndex",
              path: "cars",

              component: () =>
                import("@/views/apps/car-report/CarReport").then(
                  (x) => x.CarReportIndex || x
                ),
            },
          ],
        },
        {
          name: "pdf",
          path: "/pdf",
          component: () =>
            import("@/components/pdf/index").then((x) => x.Index || x),
          children: [
            {
              name: "view10x15_TO",
              path: "view10x15_TO",
              component: () =>
                import("@/components/pdf/index").then(
                  (x) => x.view10x15_TO || x
                ),
            },
            {
              name: "view10x15_FROM",
              path: "view10x15_FROM",
              component: () =>
                import("@/components/pdf/index").then(
                  (x) => x.view10x15_FROM || x
                ),
            },
            {
              name: "view10x15_LIST",
              path: "view10x15_LIST",
              component: () =>
                import("@/components/pdf/index").then(
                  (x) => x.view10x15_LIST || x
                ),
            },
            {
              name: "view5x3_TO",
              path: "view5x3_TO",
              component: () =>
                import("@/components/pdf/index").then((x) => x.view5x3_TO || x),
            },
            {
              name: "view5x3_FROM",
              path: "view5x3_FROM",
              component: () =>
                import("@/components/pdf/index").then(
                  (x) => x.view5x3_FROM || x
                ),
            },
            {
              name: "pdfDesigner",
              path: "pdfDesigner",
              component: () =>
                import("@/components/pdf/index").then(
                  (x) => x.pdfDesigner || x
                ),
            },
            {
              name: "pdfViewer",
              path: "pdfViewer",
              component: () =>
                import("@/components/pdf/index").then((x) => x.pdfViewer || x),
            },
            // {
            //   name: "stickerPDF",
            //   path: "stickerPDF",
            //   component: () =>
            //     import("@/components/pdf/index").then((x) => x.stickerPDF || x),
            // },
          ],
        },
        {
          name: "editor",
          path: "/editor",
          component: () =>
            import("@/components/Editor/index").then((x) => x.Editor || x),
        },
        {
          name: "poster",
          path: "/poster",
          component: () => import("@/components/PtnAllService/Poster/index"),
        },
        {
          name: "settings",
          path: "/settings",
          component: () =>
            import("@/components/PtnAllService/Settings/index").then(
              (x) => x.Index || x
            ),
          children: [
            {
              name: "menuSettings",
              path: "menuSettings",
              component: () =>
                import("@/components/PtnAllService/Settings/index").then(
                  (x) => x.menuSettings || x
                ),
            },
            {
              name: "menuSettingsAction",
              path: "menuSettingsAction",
              component: () =>
                import("@/components/PtnAllService/Settings/index").then(
                  (x) => x.menuSettingsAction || x
                ),
            },
            {
              name: "statusSettings",
              path: "statusSettings",
              component: () =>
                import("@/components/PtnAllService/Settings/index").then(
                  (x) => x.statusSettings || x
                ),
            },
            {
              name: "activityLogs",
              path: "activityLogs",
              component: () =>
                import("@/components/PtnAllService/Settings/index").then(
                  (x) => x.activityLogs || x
                ),
            },
          ],
        },
        {
          name: "sparepart",
          path: "sparepart",
          component: () =>
            import("@/components/PtnAllService/Contract/sparePart"),
        },
        {
          name: "index",
          path: "dailyWorkReport",
          component: () =>
            import("@/components/PtnAllService/Contract/DailyWorkReport/index"),
        },
        {
          name: "service",
          path: "service",
          component: () =>
            import("@/components/PtnAllService/masterData/index").then(
              (x) => x.service || x
            ),
        },
        {
          name: "Agent",
          path: "/Agent",
          component: () => import("@/components/PtnAllService/Agent/index"),
        },
        {
          name: "profile",
          path: "/profile",
          component: () => import("@/components/PtnAllService/Agent/profile"),
          children: [
            {
              name: "profileEdit",
              path: "edit",
              component: () =>
                import("@/components/PtnAllService/Agent/profileEdit"),
            },
            {
              name: "signature",
              path: "signature",
              component: () =>
                import("@/components/PtnAllService/Agent/signature"),
            },
            {
              name: "viewProfile",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/Agent/viewProfile"),
            },
          ],
        },
        {
          name: "Customer",
          path: "/Customer",
          component: () =>
            import("@/components/PtnAllService/masterData/index").then(
              (x) => x.customer || x
            ),
        },
        {
          name: "contract",
          path: "/Contract",
          component: () =>
            import("@/components/PtnAllService/masterData/index").then(
              (x) => x.contract || x
            ),
        },
        {
          name: "AssetDevice",
          path: "/AssetDevice",
          component: () =>
            import("@/components/PtnAllService/assetDevice/index"),
        },
        //stock
        {
          name: "stock",
          path: "/stock",
          component: () => import("@/components/PtnAllService/stock/index"),
        },
        {
          name: "warehouse",
          path: "/warehouse",
          component: () =>
            import("@/components/PtnAllService/stock/Warehouse/WarehouseIndex"),
        },
        {
          name: "warehouseDestroy",
          path: "/warehouseDestroy",
          component: () =>
            import("@/components/PtnAllService/stock/WarehouseDestroy"),
        },
        {
          name: "searchDeviceSerialNumber",
          path: "/searchDeviceSerialNumber",
          component: () =>
            import("@/components/PtnAllService/stock/searchDeviceSerialNumber"),
        },
        {
          name: "searchItemQr",
          path: "/searchItemQr",
          component: () =>
            import("@/components/PtnAllService/stock/searchItemQr"),
        },
        {
          name: "searchQrNumber",
          path: "/searchQrNumber",
          component: () =>
            import("@/components/PtnAllService/stock/searchQrNumber"),
        },
        {
          name: "ListOfProductsReceivedIndex",
          path: "/list-of-products-received",
          component: () =>
            import(
              "@/components/PtnAllService/stock/ListOfProductsReceived/ListOfProductsReceivedIndex"
            ),
        },
        {
          name: "ListOfProductsIssuedIndex",
          path: "/list-of-products-issued-sv",
          component: () =>
            import(
              "@/components/PtnAllService/stock/ListOfProductsIssuedSV/ListOfProductsIssuedIndex"
            ),
        },
        {
          name: "ListOfProductsIssuedIndex",
          path: "/list-of-products-issued-so",
          component: () =>
            import(
              "@/components/PtnAllService/stock/ListOfProductsIssuedSO/ListOfProductsIssuedIndex"
            ),
        },
        {
          name: "ListOfProductsIssuedIndex",
          path: "/list-of-products-issued-tn",
          component: () =>
            import(
              "@/components/PtnAllService/stock/ListOfProductsIssuedTN/ListOfProductsIssuedIndex"
            ),
        },
        {
          name: "searchSerialNumber",
          path: "/searchSerialNumber",
          component: () =>
            import("@/components/PtnAllService/stock/searchSerialNumber"),
        },
        {
          name: "checkTransactionNoIndex",
          path: "/check-transactionNo",
          component: () =>
            import(
              "@/components/PtnAllService/stock/CheckTransactionNo/CheckTransactionNoIndex"
            ),
        },
        {
          name: "stockItem",
          path: "/stockItem",
          component: () => import("@/components/PtnAllService/stock/stockItem"),
        },
        {
          name: "product",
          path: "/product",
          component: () => import("@/components/PtnAllService/stock/product"),
        },
        {
          name: "Consumables",
          path: "/consumables",
          component: () =>
            import("@/components/PtnAllService/stock/consumables"),
        },
        {
          name: "branch",
          path: "/branch",
          component: () => import("@/components/PtnAllService/stock/branch"),
        },
        {
          name: "van",
          path: "/van",
          component: () => import("@/components/PtnAllService/stock/van"),
        },
        {
          name: "unit",
          path: "/unit",
          component: () => import("@/components/PtnAllService/stock/unit"),
        },
        {
          name: "stockAmt",
          path: "/stockAmt",
          component: () => import("@/components/PtnAllService/stock/stockAmt"),
        },
        {
          name: "PartAndServiceSupportIndex",
          path: "/part-service-support",
          component: () =>
            import(
              "@/components/PtnAllService/stock/PartAndServiceSupport/PartAndServiceSupportIndex"
            ),
        },
        {
          name: "claimPartReceives",
          path: "/claim-part-receives",
          component: () =>
            import(
              "@/components/PtnAllService/stock/ClaimPartReceives/ClaimPartReceivesIndex"
            ),
        },
        {
          name: "stockReceives",
          path: "/stockReceives",
          component: () =>
            import(
              "@/components/PtnAllService/stock/StockReceives/StockReceives"
            ),
        },
        {
          name: "ReturnJobSheet",
          path: "/returnJobSheet",
          component: () =>
            import(
              "@/components/PtnAllService/stock/ReturnJobSheet/ReturnJobSheet"
            ),
        },
        {
          name: "PurchaseOrder",
          path: "/purchaseOrder-stock",
          component: () =>
            import(
              "@/components/PtnAllService/stock/PurchaseOrder/PurchaseOrder"
            ),
        },
        {
          name: "reserveProduct",
          path: "/reserveProduct",
          component: () =>
            import("@/components/PtnAllService/stock/reserveProduct"),
        },
        {
          name: "OrganizeItemsIndex",
          path: "/organize-items",
          component: () =>
            import(
              "@/components/PtnAllService/stock/OrganizeItems/OrganizeItemsIndex"
            ),
        },
        {
          name: "TemporaryDeliveryItemsIndex",
          path: "/temporary-delivery-items",
          component: () =>
            import(
              "@/components/PtnAllService/stock/TemporaryDeliveryItems/TemporaryDeliveryItemsIndex"
            ),
        },

        {
          name: "ForeCastProducts",
          path: "/forecast-products",
          component: () =>
            import(
              "@/components/PtnAllService/stock/ForeCast/ForeCastProducts"
            ),
        },

        {
          name: "ForeCastParts",
          path: "/forecast-parts",
          component: () =>
            import("@/components/PtnAllService/stock/ForeCast/ForeCastParts"),
        },

        {
          name: "returnproduct",
          path: "/returnproduct",
          component: () =>
            import("@/components/PtnAllService/stock/return_product"),
        },
        {
          name: "testExcel",
          path: "/testExcel",
          component: () =>
            import("@/components/PtnAllService/Excel/Export_Stock_Test"),
        },
        // {
        //   name: "importSerialNumber",
        //   path: "/importSerialNumber",
        //   component: () =>
        //     import("@/components/PtnAllService/stock/importSerialNumber"),
        // },

        //supplier
        {
          name: "supplier",
          path: "/supplier",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.Supplier || x
            ),
        },
        {
          name: "quotation",
          path: "/quotation",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.Quotation || x
            ),
          children: [
            {
              name: "quotationCreatePage",
              path: "add",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.QuotationCreate || x
                ),
            },
            {
              name: "quotationEditPage",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.QuotationEdit || x
                ),
            },
            {
              name: "convertToSaleOrder",
              path: "convertToSaleOrder/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ConvertToSaleOrder || x
                ),
            },
          ],
        },
        {
          name: "saleOrder",
          path: "/saleOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.SaleOrder || x
            ),
          children: [
            {
              name: "saleOrderCreatePage",
              path: "add",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.SaleOrderCreate || x
                ),
            },
            {
              name: "saleOrderEditPage",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.SaleOrderEdit || x
                ),
            },
            {
              name: "saleOrderViewPage",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.SaleOrderView || x
                ),
            },
            {
              name: "salesOrderFromTemporaryDeliveryOrderCreatePage",
              path: "salesOrderFromTemporaryDeliveryOrderCreatePage",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.SaleOrderFromTemporaryDeliveryOrder || x
                ),
            },
            {
              name: "salesOrderFromBookingOrderCreatePage",
              path: "salesOrderFromBookingOrderCreatePage",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.SaleOrderFromBookingOrder || x
                ),
            },
          ],
        },
        {
          name: "temporaryDeliveryOrder",
          path: "/temporaryDeliveryOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.TemporaryDeliveryOrder || x
            ),
          children: [
            {
              name: "temporaryDeliveryOrderCreatePage",
              path: "add",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.TemporaryDeliveryOrderCreate || x
                ),
            },
            {
              name: "temporaryDeliveryOrderEditPage",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.TemporaryDeliveryOrderEdit || x
                ),
            },
            {
              name: "temporaryDeliveryOrderViewPage",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.TemporaryDeliveryOrderView || x
                ),
            },
            {
              name: "TNFromBOCreatePage",
              path: "TNFromBOCreatePage",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.TemporaryDeliveryOrderFromBookingOrder || x
                ),
            },
          ],
        },
        {
          name: "workOrder",
          path: "/workOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.WorkOrder || x
            ),
          children: [
            {
              name: "workOrderViewPage",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.WorkOrderView || x
                ),
            },
          ],
        },
        {
          name: "purchaseOrder",
          path: "/purchaseOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.PurchaseOrder || x
            ),
          children: [
            {
              name: "newPurchaseOrder",
              path: "new",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.PurchaseOrderCreate || x
                ),
            },
            {
              name: "editPurchaseOrder",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.PurchaseOrderEdit || x
                ),
            },
          ],
        },
        {
          name: "bookingOrder",
          path: "/bookingOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.BookingOrder || x
            ),
          children: [
            {
              name: "newBookingOrder",
              path: "new",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.BookingOrderCreate || x
                ),
            },
            {
              name: "editBookingOrder",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.BookingOrderEdit || x
                ),
            },
          ],
        },
        // Approve
        {
          name: "approveWorkOrder",
          path: "/approveWorkOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.ApproveWorkOrder || x
            ),
          children: [
            {
              name: "approveWorkOrderEditPage",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApproveWorkOrderEdit || x
                ),
            },
            {
              name: "approveWorkOrderViewPage",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApproveWorkOrderView || x
                ),
            },
          ],
        },
        {
          name: "approveTemporaryDeliveryOrder",
          path: "/approveTemporaryDeliveryOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.ApproveTemporaryDeliveryOrder || x
            ),
          children: [
            {
              name: "approveTemporaryDeliveryOrderEditPage",
              path: "edit/:status/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApproveTemporaryDeliveryOrderEdit || x
                ),
            },
            {
              name: "approveTemporaryDeliveryOrderViewPage",
              path: "view/:status/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApproveTemporaryDeliveryOrderView || x
                ),
            },
          ],
        },
        {
          name: "approveSaleOrder",
          path: "/approveSaleOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.ApproveSaleOrder || x
            ),
          children: [
            {
              name: "approveSaleOrderEditPage",
              path: "edit/:status/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApproveSaleOrderEdit || x
                ),
            },
            {
              name: "approveSaleOrderViewPage",
              path: "view/:status/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApproveSaleOrderView || x
                ),
            },
          ],
        },
        {
          name: "approveQuotation",
          path: "/approveQuotation",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.ApproveQuotation || x
            ),
          children: [
            {
              name: "approveQuotationEditPage",
              path: "edit/:status/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApproveQuotationEdit || x
                ),
            },
            {
              name: "approveQuotationViewPage",
              path: "view/:status/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApproveQuotationView || x
                ),
            },
          ],
        },
        {
          name: "approvePurchaseOrder",
          path: "/approvePurchaseOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.ApprovePurchaseOrder || x
            ),
          children: [
            {
              name: "approvePurchaseOrderEditPage",
              path: "edit/:status/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApprovePurchaseOrderEdit || x
                ),
            },
            {
              name: "approvePurchaseOrderViewPage",
              path: "view/:status/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.ApprovePurchaseOrderView || x
                ),
            },
          ],
        },
        // Sales Approve
        {
          name: "salesApproveSaleOrder",
          path: "/salesApproveSaleOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.SalesApproveSaleOrder || x
            ),
          children: [
            {
              name: "salesApproveSaleOrderEditPage",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.SalesApproveSaleOrderEdit || x
                ),
            },
            {
              name: "salesApproveSaleOrderViewPage",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.SalesApproveSaleOrderView || x
                ),
            },
          ],
        },
        {
          name: "salesApproveTemporaryDeliveryOrder",
          path: "/salesApproveTemporaryDeliveryOrder",
          component: () =>
            import("@/components/PtnAllService/Supplier/index").then(
              (x) => x.SalesApproveTemporaryDeliveryOrder || x
            ),
          children: [
            {
              name: "salesApproveTemporaryDeliveryOrderEditPage",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.SalesApproveTemporaryDeliveryOrderEdit || x
                ),
            },
            {
              name: "salesApproveTemporaryDeliveryOrderViewPage",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/Supplier/index").then(
                  (x) => x.SalesApproveTemporaryDeliveryOrderView || x
                ),
            },
          ],
        },

        {
          name: "brandAsset",
          path: "/brandAsset",
          component: () =>
            import("@/components/PtnAllService/assetDevice/brandAsset"),
        },
        {
          name: "deviceTypeAsset",
          path: "/deviceTypeAsset",
          component: () =>
            import("@/components/PtnAllService/assetDevice/deviceTypeAsset"),
        },
        {
          name: "brand",
          path: "/brand",
          component: () => import("@/components/PtnAllService/Contract/brand"),
        },
        {
          name: "RepairClaimGoods",
          path: "/repair-claim-goods-receipt-delivery",
          component: () =>
            import("@/components/PtnAllService/Contract/index").then(
              (x) => x.RepairClaimGoods || x
            ),
          children: [
            {
              name: "createRepairClaimGoods",
              path: "create",
              component: () =>
                import("@/components/PtnAllService/Contract/index").then(
                  (x) => x.CreateRepairClaimGoods || x
                ),
            },
            {
              name: "editRepairClaimGoods",
              path: "edit/:id",
              component: () =>
                import("@/components/PtnAllService/Contract/index").then(
                  (x) => x.EditRepairClaimGoods || x
                ),
            },
            {
              name: "viewRepairClaimGoods",
              path: "view/:id",
              component: () =>
                import("@/components/PtnAllService/Contract/index").then(
                  (x) => x.ViewRepairClaimGoods || x
                ),
            },
          ],
        },
        {
          name: "RepairClaimGoodsReceive",
          path: "/repair-claim-goods-receives",
          component: () =>
            import(
              "@/components/PtnAllService/stock/RepairClaimGoods/index"
            ).then((x) => x.RepairClaimGoodsReceive || x),
        },
        {
          name: "ClaimOrganizeItemsIndex",
          path: "/claim-organize-items",
          component: () =>
            import(
              "@/components/PtnAllService/stock/ClaimOrganizeItems/OrganizeItemsIndex"
            ),
        },
        {
          name: "model",
          path: "model",
          component: () => import("@/components/PtnAllService/Contract/model"),
        },
        {
          name: "type",
          path: "type",
          component: () => import("@/components/PtnAllService/Contract/type"),
        },
        {
          name: "consumableType",
          path: "consumableType",
          component: () =>
            import("@/components/PtnAllService/Contract/consumableType"),
        },
        // HR
        {
          name: "warningLetter",
          path: "/warningLetter",
          component: () =>
            import("@/components/PtnAllService/HR/warningLetter"),
        },
        {
          name: "leaveRequest",
          path: "leaveRequest",
          component: () => import("@/components/PtnAllService/HR/leaveRequest"),
        },
        {
          name: "outsideRequest",
          path: "outsideRequest",
          component: () =>
            import("@/components/PtnAllService/HR/outsideRequest"),
        },
        {
          name: "salesOutsideRequest",
          path: "salesOutsideRequest",
          component: () =>
            import("@/components/PtnAllService/HR/salesOutsideRequest"),
        },
        {
          name: "OTRequest",
          path: "OTRequest",
          component: () => import("@/components/PtnAllService/HR/OTRequest"),
        },
        {
          name: "meetingroom",
          path: "meetingroom",
          component: () => import("@/components/PtnAllService/HR/Meeting_Room"),
        },
        {
          name: "banner",
          path: "banner",
          component: () => import("@/components/PtnAllService/HR/banner"),
        },
        {
          name: "ManageAnnouncement",
          path: "ManageAnnouncement",
          component: () =>
            import("@/components/PtnAllService/HR/ManageAnnouncement"),
        },
        {
          name: "manageAdvertisements",
          path: "manageAdvertisements",
          component: () =>
            import("@/components/PtnAllService/HR/manageAdvertisements"),
        },
        {
          name: "manageCalendar",
          path: "manageCalendar",
          component: () =>
            import("@/components/PtnAllService/HR/manageCalendar"),
        },
        {
          name: "manageCars",
          path: "manageCars",
          component: () =>
            import("@/components/PtnAllService/Cars/index").then(
              (x) => x.Index
            ),
        },
        {
          // ข้อมูลการใช้รถ
          name: "manageCarsData",
          path: "manageCarsData",
          component: () =>
            import("@/components/PtnAllService/Cars/index").then(
              (x) => x.manageCarsData
            ),
        },
        // ข้อมูลบันทึกเวลาทำงานของพนักงาน
        {
          name: "manageTimeAttendance",
          path: "manageTimeAttendance",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.manageTimeAttendance
            ),
        },
        // ประวัติคะแนน
        {
          name: "historyPointPage",
          path: "historyPointPage",
          component: () =>
            import("@/components/PtnAllService/HR/index").then(
              (x) => x.historyPointPage
            ),
        },
        {
          name: "test",
          path: "/test",
          component: () =>
            import("@/components/PtnAllService/Contract/test.vue"),
        },
        {
          name: "device",
          path: "/device",
          component: () => import("@/components/PtnAllService/Contract/device"),
        },
        {
          name: "tools",
          path: "/tools",
          component: () =>
            import("@/components/PtnAllService/masterData/index").then(
              (x) => x.tools || x
            ),
        },
        {
          name: "Meter",
          path: "/meter",
          component: () => import("@/components/PtnAllService/Contract/Meter"),
        },
        {
          name: "GroupMeter",
          path: "/group-meter",
          component: () =>
            import("@/components/PtnAllService/Contract/GroupMeter"),
        },
        {
          name: "detailsDelivery",
          path: "/detailsDelivery",
          component: () =>
            import("@/components/PtnAllService/Contract/details_delivery"),
        },
        {
          name: "delivery",
          path: "/delivery",
          component: () =>
            import("@/components/PtnAllService/Contract/delivery"),
        },
        {
          name: "pickup",
          path: "/pickup",
          component: () => import("@/components/PtnAllService/Contract/pickup"),
        },
        {
          name: "detailsBill",
          path: "/detailsBill",
          component: () =>
            import("@/components/PtnAllService/Contract/details_bill"),
        },
        {
          name: "detailsService",
          path: "/detailsService",
          component: () =>
            import("@/components/PtnAllService/Contract/details_service"),
          children: [
            {
              name: "newDetailsService",
              path: "new/:id",
              component: () =>
                import(
                  "@/components/PtnAllService/Contract/detail_service/newDetailsService"
                ),
            },
          ],
        },
        {
          name: "DetailsService",
          path: "/details-service",
          component: () =>
            import("@/components/PtnAllService/Contract/index").then(
              (x) => x.Service || x
            ),
        },
        {
          name: "detailsInstall",
          path: "/detailsInstall",
          component: () =>
            import("@/components/PtnAllService/Contract/details_install"),
        },
        {
          name: "detailsOther",
          path: "/detailsOther",
          component: () =>
            import("@/components/PtnAllService/Contract/details_other"),
        },
        {
          name: "worksheets",
          path: "/worksheets",
          component: () =>
            import("@/components/PtnAllService/Contract/worksheets"),
        },
        {
          name: "WorkingServiceReport",
          path: "/working-service-report",
          component: () =>
            import(
              "@/views/apps/working-report/working-service-report/WorkingServiceReport"
            ),
        },
        {
          name: "WorkingSaleReport",
          path: "/working-sale-report",
          component: () =>
            import(
              "@/views/apps/working-report/working-sale-report/WorkingSaleReport"
            ),
        },
        {
          name: "WorkingGeneralClericalOfficerReport",
          path: "/working-general-clerical-officer-report",
          component: () =>
            import(
              "@/views/apps/working-report/working-general-clerical-officer-report/WorkingGeneralClericalOfficerReport"
            ),
        },
        {
          name: "WorkingDeliveryAdministrativeReport",
          path: "/working-delivery-administrative-report",
          component: () =>
            import(
              "@/views/apps/working-report/working-delivery-administrative-report/WorkingDeliveryAdministrativeReport"
            ),
        },
        {
          name: "StockReport",
          path: "/stock-report",
          component: () => import("@/views/apps/stock-report/StockReport"),
        },

        // Application
        // {
        //   name: "Apps",
        //   path: "/chat",
        //   component: () => import("@/views/apps/chat/Chat"),
        // },
        {
          name: "Apps",
          path: "/chat",
          component: () => import("@/views/apps/chat/Messenger"),
        },

        // {
        //   name: "AppsNewChat",
        //   path: "/newChat",
        //   component: () => import("@/views/apps/chat/Index"),
        // },

        {
          name: "ApexCharts",
          path: "charts/apexcharts",
          component: () => import("@/views/charts/apexcharts/ApexCharts"),
        },
        {
          name: "Analytical",
          path: "dashboard",
          component: () => import("@/views/dashboards/analytical/Analytical"),
        },
        {
          name: "Modern",
          path: "dashboards/modern",
          component: () => import("@/views/dashboards/modern/Modern"),
        },
        {
          name: "Ecommerce",
          path: "dashboards/ecommerce",
          component: () => import("@/views/dashboards/ecommerce/Ecommerce"),
        },
        {
          name: "Ecommerce-shop",
          path: "apps/shop",
          component: () => import("@/views/apps/ecommerce/Listing"),
        },
        {
          name: "Contact",
          path: "apps/contact",
          component: () => import("@/views/apps/contact/Contact"),
        },
        {
          name: "ContactList",
          path: "apps/contact-list",
          component: () => import("@/views/apps/contact-list/ContactList"),
        },
        {
          name: "ContactGrid",
          path: "apps/contact-grid",
          component: () => import("@/views/apps/contact-grid/ContactGrid"),
        },
        {
          name: "Email",
          path: "apps/email/inbox",
          component: () => import("@/views/apps/email/Email"),
          children: [
            {
              path: "/apps/email/:mailType",
              name: "MailIndex",
              component: () => import("@/views/apps/email/EmailList.vue"),
            },
            {
              path: "/apps/email/:mailType/0/:uuid",
              name: "MailDetail1",
              component: () => import("@/views/apps/email/EmailDetail.vue"),
            },
            {
              path: "/apps/email/0/:mailTag",
              name: "MailTag",
              component: () => import("@/views/apps/email/EmailList.vue"),
            },
            {
              path: "/apps/email/0/:mailTag/0/:uuid",
              name: "MailDetail",
              component: () => import("@/views/apps/email/EmailDetail.vue"),
            },
          ],
        },
        {
          name: "Todo",
          path: "apps/todo",
          component: () => import("@/views/apps/todo/Todo"),
        },
        {
          name: "DragnDrop",
          path: "extra-components/DragnDrop",
          component: () =>
            import("@/views/extra-components/drag-n-drop/DragnDrop"),
        },
        {
          name: "Kanban",
          path: "kanban",
          component: () => import("@/components/kanban/Kanban"),
        },
      ],
    },
    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "BoxedLogin",
          path: "boxedlogin",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        {
          name: "FullLogin",
          path: "fulllogin",
          component: () => import("@/views/authentication/FullLogin"),
        },
        {
          name: "BoxedRegister",
          path: "boxedregister",
          component: () => import("@/views/authentication/BoxedRegister"),
        },
        {
          name: "FullRegister",
          path: "fullregister",
          component: () => import("@/views/authentication/FullRegister"),
        },

        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
      ],
    },
    {
      path: "/hrm",
      beforeEnter: (to, from, next) => {
        window.location.href = `${process.env.VUE_APP_AAA_HRM}/callback?token=${userData.token}`;
      },
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeEach((to, from, next) => {
  NProgress.start();

  const menu = JSON.parse(localStorage.getItem("menu"));

  if (menu) {
    const authorizedPaths = extractPathsFromMenu(menu);
    const basePath = `/${to.path.split("/")[1]}`; // Extracts the base part of the current route

    if (authorizedPaths.includes(basePath)) {
      next();
    } else {
      next("/"); // ไปยังหน้า Dashboard หรือหน้า Unauthorized
    }
  } else {
    next();
  }

  NProgress.done();
  // If this isn't an initial page load.
});

function extractPathsFromMenu(menu) {
  const paths = [
    "/dashboard",
    "/login",
    "/poster",
    "/profile",
    "/scanReceiveItems",
    "/emailExportPDF",
    "/demo-tablets",
    "/demo-ads",
    "/warningLetter",
    "/warningLetterExportPDF",
    "/receiptDeliveryForRepairClaim",
    "/returnJobSheet",
    "/exportPDFRequestQuotation",
    "/exportPDFRequestQuotationApp",
    "/dailyWorkReport",
  ]; // Include default paths

  let warehouseExists = false; // Flag to check if '/warehouse' is in the menu

  for (const item of menu) {
    if (item.subMenu && item.subMenu.length > 0) {
      for (const subItem of item.subMenu) {
        if (!subItem.path) {
          continue;
        }
        const basePath = `/${subItem.path.split("/")[1]}`; // Extracts the base path
        if (!paths.includes(basePath)) {
          paths.push(basePath);
        }
        if (basePath === "/warehouse") {
          warehouseExists = true; // Set the flag if '/warehouse' is found
        }
      }
    }
  }

  const userData = JSON.parse(Decode.decode(localStorage.getItem("iworkUser")));

  if (
    userData.workGroupId === 21 ||
    userData.workGroupId === 8 ||
    userData.workGroupId === 13
  ) {
    paths.push("/settings", "/pdf", "/attendance");
  }

  // If '/warehouse' is in the menu, add additional specific paths
  if (warehouseExists) {
    paths.push("/searchDeviceSerialNumber", "/searchItemQr", "/searchQrNumber");
  }

  return paths;
}

export default router;
